import styled from '@emotion/styled'
// import { theme } from '../styles'

export const HeroForm = styled.div`
  position: relative;
  div {
    max-width: 350px;
    p {
      color: #000000;
      margin: 0;
      font-weight: bold;
      padding: 20px 0;
      line-height: 22px;
      &.red {
        color: #ec5d57;
      }
    }
    form {
      display: flex;
      input[type="email"] {
        text-align: center;
        max-width: 150px;
        border-radius: 5px;
        border: 1px solid #6b3c9d;
        padding: 7px;
        font-size: 16px;
        max-height: 38px;
      }
    }
    button {
      font-size: 16px;
      margin: 0 15px;
      border-radius: 5px;
      border: 1px solid #6b3c9d;
      background: #ec5d57;
      color: #ffffff;
      box-shadow: rgb(0 0 0 / 10%) 0px 1px 6px, rgb(0 0 0 / 20%) 0px 2px 24px;
      padding: 7px 20px;
      cursor: pointer;
      text-transform: none;
      font-weight: bold;
    }
  }
`