import React from 'react'
import { 
  graphql, 
  navigate 
} from 'gatsby'
import MainWrapper from '../containers/MainWrapper'
import SEO from '../components/SEO'
import Header from '../components/Header'
import Register from '../components/Register'
// import HeaderLogo from '../assets/images/6.png'

const Index = ({ 
  data: { images }, 
  pageContext: { slug }, 
  location 
}) => {
  const pagesImages = (id) => {
    return images.edges.filter((item) => {
      return parseInt(item.node.name) === id;
    })[0].node.publicURL;
  };

  return (
    <React.Fragment>
      <SEO />
      <Header slug={slug} location={location} />
      <MainWrapper>
        {/* <section style={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          height: '100vh'
        }}>
          <div>
            <div style={{ textAlign: 'center' }}>
              <img src={HeaderLogo} alt="AFC" />
            </div>
            <h3 style={{ textAlign: 'center' }}>
              Site actuellement en cours de réalisation.
            </h3>
            <h4 style={{ textAlign: 'center' }}>
              Publication en juin 2023
            </h4>
          </div>
        </section> */}
        <section className="odd" style={{ paddingTop: '100px'}}>
          <div className="container">
            <Register message={`Nous œuvrons pour que les familles trouvent les ressources d’êtres plus unies et plus heureuses.`} />
          </div>
          <div className="right">
            <img src={pagesImages(7)} alt="" />
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-6">
                <div className="hero-item">
                  <span className="label">Ouvert à tous</span>
                  <h2>Nous vous accueillons là où vous en êtes :</h2>
                  <p><strong>Nous proposons des ressources</strong> pour les parents seuls, les couples, les familles de tous âges et de toutes conditions : 
                    des formations, des conférences, une bibliothèque, des événements familiaux, des groupes d’entre-aide.</p> 
                </div>
              </div>
              <div className="col-6">
                <img src={pagesImages(1)} alt="" />
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-6">
                <img src={pagesImages(2)} alt="" />
              </div>
              <div className="col-6">
                <div className="hero-item">
                  <span className="label">Catholique</span>
                  <h2>On se serre les coudes et on s'élève :</h2>
                  <p>L'association fait partie d'un réseau de +350 associations familiales catholiques.</p>
                  <p>Catholiques décomplexés, ouverts par conviction, et très heureux de participer au bien commun.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="odd">
          <div className="container">
            <Register 
              className="red"
              style={{paddingTop: 0}} 
              message={`Bon, vous n'allez pas restez là à vous demander quoi faire. Rejoignez-nous !`} 
            />
          </div>
          <div className="right">
            <img src={pagesImages(7)} alt="" />
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-6">
                <div className="hero-item">
                  <span className="label">Chantiers Education</span>
                  <h2>Des groupes d’échanges et de progression</h2>
                  <p>Un chantier est un groupe de partage de 6 à 10 mères, ou pères, ou grands-parents, qui se réunit régulièrement tout au long de l’année autour d’un thème précis pour partager et progresser.</p>
                  <p>Nous avons aujourd’hui 4 à 5 chantiers actifs.</p>
                  <button onClick={() => { typeof window !== 'undefined' && window.open('mailto:contact@afcbayonneangletbiarritz.fr') }}>Contactez nous pour en savoir plus</button>
                </div>
              </div>
              <div className="col-6">
                <img src={pagesImages(3)} alt="" />
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-6">
                <div className="hero-item">
                  <span className="label">Conférences</span>
                  <h2>Nous organisons 4 à 6 conférences par an</h2>
                  <p>Sur tous les sujets qui peuvent nous faire grandir dans notre compréhension des sujets d’éducation et de société. Les conférenciers sont toujours référents dans leur domaine.</p>
                  <p>Pour recevoir les informations sur les conférences, inscrivez-vous ici gratuitement ou bien adhérez aux AFC.</p>
                  <button onClick={() => navigate(`/inscription/`)}>Je m'inscris</button>
                </div>
              </div>
              <div className="col-6">
                <img src={pagesImages(4)} alt="" />
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="hero-item">
                  <span className="label">Formations</span>
                  <h2>Toute l’année, des propositions de formation</h2>
                  <p>Sur l’éducation affective et sexuelle pour les filles et les garçons, avec ou sans parent, sur les techniques de prises de parole en public et d’animation de groupe, sur la doctrine sociale de l’Eglise, etc. Les sujets sont larges.</p>
                  <p>Pour recevoir les informations sur les conférences, inscrivez-vous ici gratuitement ou bien adhérez aux AFC.</p>
                  <button onClick={() => navigate(`/inscription/`)}>Je m'inscris</button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-6">
                <img src={pagesImages(5)} alt="" />
              </div>
              <div className="col-6">
                <div className="hero-item">
                  <span className="label">Bibli des Familles</span>
                  <h2>Une bibliothèque pour les enfants</h2>
                  <p>Nous avons créé à Bayonne un bibliothèque pour vos enfants de 3 à 15 ans avec plus de 2000 ouvrages de qualité.
                    Des ateliers sont aussi régulièrement proposés.</p>
                  <p>Adresse : Bibli des Familles à la Maison diocésaine, 10 avenue Jean Darrigrand, 64100 Bayonne.</p>

                    <p>Pour connaître les conditions d’accès et horaires, contactez Aude.</p>
                  <button>J'écris à Aude</button>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="odd">
          <div className="container">
            <div className="row">
              <div className="donate">
                <h2>Vous voulez donner un peu d'argent déductible de votre IR ? Ce serait génial.</h2>
                <form>
                  <div className="amounts">
                    <button onClick={(e) => {e.preventDefault()}} data-id="button-10">10 €</button>
                    <span className="caption">= don net<br />3,34 €</span>
                  </div>

                  <div className="amounts">
                    <button onClick={(e) => {e.preventDefault()}} data-id="button-25">25 €</button>
                    <span className="caption">= don net<br />3,34 €</span>
                  </div>

                  <div className="amounts">
                    <button onClick={(e) => {e.preventDefault()}} data-id="button-50">50 €</button>
                    <span className="caption">= don net<br />3,34 €</span>
                  </div>

                  <div className="amounts">
                    <button onClick={(e) => {e.preventDefault()}} data-id="button-100">100 €</button>
                    <span className="caption">= don net<br />3,34 €</span>
                  </div>

                  <div className="amounts">
                    <input data-id="amount-free" placeholder="autre" />
                    <span className="caption">= don net<br />3,34 €</span>
                  </div>

                  <button onClick={(e) => { e.preventDefault(); if (typeof window !== 'undefined') { window.open('https://www.helloasso.com/associations/afc-bayonne-anglet-biarritz/adhesions/faites-decoller-l-asso-familiale-catholique-de-bayonne-anglet-biarritz-2')}} }>Je donne</button>
                </form>
              </div>
            </div>
          </div>
        </section> */}
      </MainWrapper>
    </React.Fragment>
  )
}

export default Index

export const pageQuery = graphql`
  query homePage {
    images: allFile {
      edges {
        node {
          id
          publicURL
          name
        }
      }
    }
  }
`;