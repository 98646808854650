import React from 'react'
import { navigate } from 'gatsby'
import { HeroForm } from '../containers/HeroForm'
import * as EmailValidator from 'email-validator'


class Register extends React.Component {

  state = {
    email: null
  }

  getLocalStorage = () => {
    if (typeof localStorage !== 'undefined') {
      const user = localStorage.getItem('afc-registred-user');
      return user;
    }
    return false;
  }

  handleEmail = (e) => {
    this.setState({ email: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (EmailValidator.validate(this.state.email)) {
      if (typeof localStorage !== 'undefined') {
        if (!this.getLocalStorage()) {
          localStorage.setItem('afc-registred-user', JSON.stringify(this.state));
          navigate(`/inscription/`);
        } else {
          console.log('Already registered');
        }
      }
    } else {
      console.log('Invalid email address');
    }
  }

  render() {
    const {
      style,
      className,
      message
    } = this.props;
    // console.log(this.state);
    return (
      <HeroForm style={style}>
        <div className="row">
          <div>
            <p className={className}>{message}</p>
            <form>
              <input onChange={this.handleEmail} type="email" placeholder="Votre email" />
              <button type="submit" onClick={this.handleSubmit}>Je m'inscris</button>
            </form>
          </div>
        </div>
      </HeroForm>
    )
  }
}

export default Register;